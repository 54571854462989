<template>
  <div class="panel-detail panel-fixed panel-with-actions">
    <div class="panel-header">
      <h1 class="page-header">
        {{ form.number ? `${$t('Quotation')} ${form.number}` : $t('Quotation') }}
        <!-- TODO Check -->
        <div class="page-header-actions" v-if="$route.params.id && quotationDetail">
          <b-btn
            variant="outline-primary"
            v-has-perms="downloadPDFRolePerm"
            :title="'Download PDF' | translate"
            @click.prevent="downloadPDF()"
          >
            <i class="fas fa-file-download"></i>
          </b-btn>
        </div>
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Quotations' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Quotation' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <quotation-edit :id="$route.params.id"/>
  </div>
</template>

<script>
import { ROUTES as QUOTATION_ROUTES } from './router'
import { TYPES as QUOTATION_TYPES } from './store'
import { ROUTES as PROPOSAL_ROUTES } from '../../proposal/proposal/router'
import QuotationEdit from './QuotationEdit'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuotationEditPage',
  components: {
    'quotation-edit': QuotationEdit
  },
  data () {
    return {
      pageName: 'Quotation',
      downloadPDFRolePerm: 'quotation_quotation_print_pdf',
      form: {}
    }
  },
  created () {
    if (this.$route.params.id) {
      this.retrieve({ id: this.$route.params.id })
    }
  },
  computed: {
    isYardQuotation () {
      const yardQuotationRouter = [
        QUOTATION_ROUTES.YARD_QUOTATION_CREATE,
        QUOTATION_ROUTES.YARD_QUOTATION_LIST,
        QUOTATION_ROUTES.YARD_QUOTATION_DETAIL,
        QUOTATION_ROUTES.QUOTATION_CREATE_YARD,
        PROPOSAL_ROUTES.PROPOSAL_CREATE_QUOTATION
      ]
      if (yardQuotationRouter.includes(this.$route.name)) {
        return true
      }
      return false
    },
    ...mapGetters({
      quotationDetail: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapActions({
      retrieve: QUOTATION_TYPES.GENERIC.quotation.quotation.DETAIL.ACTIONS.retrieve,
      download: QUOTATION_TYPES.GENERIC.quotation.quotation.LIST.ACTIONS.download
    }),
    goToList () {
      if (this.isYardQuotation) {
        this.$router.push({ name: QUOTATION_ROUTES.YARD_QUOTATION_LIST })
      } else {
        this.$router.push({ name: QUOTATION_ROUTES.QUOTATION_LIST })
      }
    },
    downloadPDF () {
      const fileName = `${this.$t('Quotation')}_${this.quotationDetail.number}_${this.quotationDetail.date_q}.pdf`
      this.download({ id: this.quotationDetail.id, action: 'print-pdf', simpleFileName: fileName })
    }
  }
}
</script>
